<!--
 * @Author: Jerry
 * @Date: 2021-03-05 14:49:45
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-05 15:10:58
 * @Description: 全文检索结果页
-->
<template>
  <div class="moregoods">
    <top></top>
    <search @FTSSearch="FTSSearch"></search>
    <!-- 主题内容 -->
    <div class="contain">
      <div class="crumb">
        <a class="F24 FBold co_333">搜索结果：</a>
      </div>
      <!-- 商品列表 -->
      <goodsList
        ref="goodsList"
        style="width: 1225px"
        :dataSource="dataSource"
      ></goodsList>
      <div class="Mtp60">
        <el-pagination
          class="floatR"
          background
          :total="ipagination.totalCount"
          :page-size="ipagination.size"
          :current-page="ipagination.current"
          @size-change="onSizeChange"
          @current-change="onPageChange"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[50, 100, 200, 300]"
        ></el-pagination>
      </div>
      <div class="clearfix Mbm30"></div>
    </div>
    <bottom class="bottom"></bottom>
  </div>
</template>
<script>
import Bottom from "@/components/bottom.vue";
import Search from "@/components/search.vue";
import top from "@/components/top.vue";

import goodsList from "@/components/moreGoods/goodsList";

import { getGoodsPageByFTS } from "@/api/user";
import { mapGetters } from "vuex";
import { listMixin } from "@/mixins/listMixins";
export default {
  mixins: [listMixin],
  components: {
    top,
    Search,
    Bottom,
    goodsList
  },
  name: "FTSGoods",
  computed: {
    ...mapGetters(["FTSGoods"]),
  },
  data() {
    return {
      extraParam: {
        goodsName: "",
      },
      url: {
        list: getGoodsPageByFTS,
      },
      /* 分页参数 */
      ipagination: {
        totalPage: 0,
        totalCount: 0,
        // 每页数量
        size: 50,
        // 当前页
        current: 1,
      },
      paramsList: [],
    };
  },
  mounted() {
    this.FTSSearch();
  },
  methods: {
    FTSSearch() {
      this.$set(this, "extraParam", this.$route.query);
      this.loadData();
    },
  },
};
</script>
<style scoped lang="scss">
.crumb {
  padding: 20px 0 0;
}
</style>
